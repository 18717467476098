






































































import { Component, Vue } from 'vue-property-decorator';
import { IOrderBumpCreatePayload, OfferPayload } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import { OfferType } from '@/types/offers';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import GeneralHelper from '@/shared/helpers/general';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';

@Component({
  components: {
    OrderBumpSelectOffer: () => import('./OrderBumpSelectOffer.vue'),
    OrderBumpSelect: () => import('./OrderBumpSelect.vue'),
    OrderBumpPreviewSection: () => import('./OrderBumpPreviewSection.vue'),
    MButton: () => import('@/shared/components/MButton.vue'),
    MConfirmModal: () => import('@/shared/components/MConfirmModal.vue'),
  },
})
export default class OrderBumpCreate extends Vue {
  availableOffers: Array<OfferPayload> = [];
  orderBumpPayload: IOrderBumpCreatePayload = {
    offer: {
      id: null,
      title: '',
      school_product_id: null,
      kind: OfferType.COMMON,
    },
    orderBumpList: [],
  };

  async mounted() {
    try {
      const { data } = await SparkEcommerceApiClient.offers.all(this.$route.params.id, {
        exclude_with_order_bump: true,
        kind: 'common',
        items: 999,
      });

      if (data?.payment_options) this.availableOffers = data.payment_options;
    } catch {
      ToastHelper.dangerMessage('Erro ao carregar oferta');
    }
  }

  selectOffer(offer) {
    this.orderBumpPayload.offer = offer;
  }

  selectOrderBump(orderBumpList) {
    this.orderBumpPayload.orderBumpList = orderBumpList;
  }

  async createOrderBump() {
    try {
      const { offer, orderBumpList } = this.orderBumpPayload;

      const formattedOrderBumpList = this.buildOrderBumpList(orderBumpList);

      const payload = {
        offer_id: offer.id,
        core_product_id: offer.school_product_id,
        items: formattedOrderBumpList,
        enabled: true,
      };

      await SparkEcommerceApiClient.orderBump.create(payload);

      TrackingHelper.trackOrderBumpCreated(
        offer.school_product_id,
        offer.id,
        offer.title,
        formattedOrderBumpList.length
      );

      this.$emit('back-to-list');
    } catch (error) {
      ToastHelper.dangerMessage('Erro ao criar order bump');
    }
  }

  cancelOrderBump() {
    this.$bvModal.hide('order-bump-confirm-modal');
    this.$emit('back-to-list');
  }

  buildOrderBumpList(orderBumpList) {
    return orderBumpList.map(orderBump => ({
      offer_id: orderBump.id,
      base_price: orderBump.basePrice,
      anchor_price: GeneralHelper.valueInCents(orderBump.anchorPrice),
      call_to_action: orderBump.callToAction,
      description: orderBump.description,
    }));
  }

  get saveIsEnabled() {
    const { orderBumpList } = this.orderBumpPayload;
    const hasNegativeAnchorPrice = orderBumpList.some(({ anchorPrice }) => anchorPrice <= 0);

    return this.hasOfferSelected && Boolean(orderBumpList.length) && !hasNegativeAnchorPrice;
  }

  get selectedOffer() {
    return this.orderBumpPayload.offer;
  }

  get selectedOrderBumps() {
    return this.orderBumpPayload.orderBumpList;
  }

  get hasOfferSelected() {
    return Boolean(this.orderBumpPayload.offer.id);
  }
}
